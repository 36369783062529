import { initMobile } from './modules/mobile'
import { initDropdown } from './modules/togglers'
import { initOverlay } from './modules/togglers'
import { initModal } from './modules/togglers'

document.addEventListener('DOMContentLoaded', function() {
	initMobile();
	initDropdown();
	initOverlay();
    // initModal();
});


jQuery(document).ready(function( $ ) {
	// Datepicker
	$(function() {
		$("#datepicker").datepicker({
			inline:true,
			showOtherMonths: true,
			altField: "#date-start-value",
			altFormat: "dd M yy",
			dateFormat: "dd M yy",
			onSelect: function(dateText){
				$('#date-start-output').html(dateText);
			}
		});
	});

	$(function() {
		$(".datepicker").datepicker({
			inline:true,
			showOtherMonths: true,
			altField: "#date-start-value",
			altFormat: "dd M yy",
			dateFormat: "dd M yy",
			onSelect: function(dateText){
				$('#date-start-output').html(dateText);
			}
		});
	});

	// Side submenus
	$('.has-submenu > a').click(function(e) {
		e.preventDefault();
		$(this).parent().toggleClass('submenu-level2-parent');
		$(this).parent().find('.submenu-level2').slideToggle(800);
	});

	// Big upload btn
	$( ".big-upload-input" ).not(".multiple").change(function( event ) {
		var path = $(this).get(0).value;
		var filename = path.replace(/^.*\\/, "");
		$(this).parent().find(".init").hide();
		$(this).parent().find(".path").show();
		$(this).parent().find(".path").html(filename + ' <span class="id-font4">m</span>');
		$(this).parent().find(".bin").show();
		$(this).parent().find(".photo").show();
	});
	$( ".big-upload-btn .bin").click(function() {
		$(this).parent().parent().find(".init").show();
		$(this).parent().parent().find(".path").hide();
		$(this).parent().parent().find(".bin").hide();
	});
	$( ".big-upload-input.multiple" ).change(function( event ) {
		var numFiles = $(this).get(0).files.length;
   		console.log(numFiles);
   		var that = $(this);
   		$('.big-upload-input').hide();
   		$('.add-media .button-main').removeClass('disabled');
   		if($('.stepfile .button-main').hasClass('ready')) {
   			$('.stepfile .button-main').removeClass('disabled');
   		}
   		$('.stepfile .button-main').addClass('ready');
   		$('.add-modal .stepfile .button-main').addClass('ready');
		that.parent().find(".path").addClass('not-underlined');
		var limit = 3;
		if($('.modal-wrapper').hasClass('add-model')) {
			limit = 2;
		}
   		if(numFiles > limit) {
			var filename = '<p class="title">10 médias sélectionnés</p>';
			filename += '<p class="edit">Modifier la sélection</p>';
			that.parent().find(".init").hide();
			that.parent().find(".path").show();
			that.parent().find(".path").html(filename);
			that.parent().find(".bin").show();
			that.parent().find(".photo").show();
			$('.upload-wrapper').addClass('selected');
			that.parent().find(".path").css({'overflow':'initial'});
		} else {
			that.parent().find(".init").hide();
			var filename = '<p class="title">3 médias sélectionnés</p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			that.parent().find(".path").html(filename);
			$('.upload-wrapper').removeClass('selected');
			that.parent().find(".path").css({'overflow':'initial'});
		}
	});
	$( ".upload-wrapper" ).click(function( event ) {
		if($(this).hasClass('selected')) {
			var filename = '<p class="title">10 médias sélectionnés</p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			filename += '<p class="file">fake-file.jpg <span class="id-font4">m</span></p>';
			$(this).find(".path").html(filename);
			$(this).find(".path").css({'overflow':'scroll'});
		}
	});
	/*$( ".big-upload-input.multiple .file .id-font4" ).click(function() {
		$(this).parent().addClass('test');
	});*/

	// Lang switcher
	$('.select-lang').click(function() {
		$(this).find('ul').slideToggle(200);
		$(this).find('.lang-selected').toggleClass('on');
	});

	// Tabs switch
	$('.switch-tabs a').click(function(e) {
		e.preventDefault();
		var target = $(this).attr('href');
		$('.request-cards-wrapper').hide();
		$(target).show();
		$(this).closest('.tabs').find('li').removeClass('current');
		$(this).closest('li').addClass('current');
	});

	// Sorting
	$('.sort-by').click(function() {
		$(this).find('ul').slideToggle(200);
		$(this).toggleClass('on');
	});

	// Scrollbar
	$('.scrollbar-inner').scrollbar();

	// Select menu
	var x, i, j, selElmnt, a, b, c;
	/* Look for any elements with the class "custom-select": */
	x = document.getElementsByClassName("custom-select");
	for (i = 0; i < x.length; i++) {
		selElmnt = x[i].getElementsByTagName("select")[0];
		/* For each element, create a new DIV that will act as the selected item: */
		a = document.createElement("DIV");
		a.setAttribute("class", "select-selected");
		a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
		x[i].appendChild(a);
		/* For each element, create a new DIV that will contain the option list: */
		b = document.createElement("DIV");
		b.setAttribute("class", "select-items select-hide");
		for (j = 1; j < selElmnt.length; j++) {
	    /* For each option in the original select element,
	    create a new DIV that will act as an option item: */
	    c = document.createElement("DIV");
	    c.innerHTML = selElmnt.options[j].innerHTML;
	    //c.classList.add("item");
	    c.addEventListener("click", function(e) {
	        /* When an item is clicked, update the original select box,
	        and the selected item: */
	        var y, i, k, s, h;
	        s = this.parentNode.parentNode.getElementsByTagName("select")[0];
	        h = this.parentNode.previousSibling;
	        for (i = 0; i < s.length; i++) {
	        	if (s.options[i].innerHTML == this.innerHTML) {
	        		s.selectedIndex = i;
	        		h.innerHTML = this.innerHTML;
	        		y = this.parentNode.getElementsByClassName("same-as-selected");
	        		for (k = 0; k < y.length; k++) {
	        			y[k].removeAttribute("class");
	        		}
	        		this.setAttribute("class", "same-as-selected");
	        		break;
	        	}
	        }
	        h.click();
	    });
	    b.appendChild(c);
	}
	x[i].appendChild(b);
	a.addEventListener("click", function(e) {
	    /* When the select box is clicked, close any other select boxes,
	    and open/close the current select box: */
	    e.stopPropagation();
	    closeAllSelect(this);
	    this.nextSibling.classList.toggle("select-hide");
	    this.classList.toggle("select-arrow-active");
	});
}
$('.select-selected').click(function() {
	$(this).parent().toggleClass('clicked');
});

function closeAllSelect(elmnt) {
	  /* A function that will close all select boxes in the document,
	  except the current select box: */
	  var x, y, i, arrNo = [];
	  x = document.getElementsByClassName("select-items");
	  y = document.getElementsByClassName("select-selected");
	  for (i = 0; i < y.length; i++) {
	  	if (elmnt == y[i]) {
	  		arrNo.push(i)
	  	} else {
	  		y[i].classList.remove("select-arrow-active");
	  	}
	  }
	  for (i = 0; i < x.length; i++) {
	  	if (arrNo.indexOf(i)) {
	  		x[i].classList.add("select-hide");
	  	}
	  }
	}
	/* If the user clicks anywhere outside the select box,
	then close all select boxes: */
	document.addEventListener("click", closeAllSelect);


	//// Pour la démo
	$('.goto-step1').click(function(e) {
		e.preventDefault();
		$('#library-step2').fadeOut(100);
		setTimeout(function() {
			$('#library-step1').fadeIn(100);
		}, 150);
	});
	$('.goto-step2').click(function(e) {
		e.preventDefault();
		$('#library-step1').fadeOut(100);
		$('#library-step3').fadeOut(100);
		setTimeout(function() {
			$('#library-step2').fadeIn(100);
		}, 150);
	});
	$('.form-wrapper .half-block-container').click(function() {
		$('.form-wrapper .half-block-container').toggleClass('campaignKind');
	});
	$('.goto-step3').click(function(e) {
		e.preventDefault();
		$('#library-step2').fadeOut(100);
		console.log('vu');
		$('#library-step4').fadeOut(100);
		setTimeout(function() {
			$('#library-step3').fadeIn(100);
		}, 150);
	});
	$('.goto-step4').click(function(e) {
		e.preventDefault();
		$('#library-step3').fadeOut(100);
		setTimeout(function() {
			$('#library-step4').fadeIn(100);
		}, 150);
	});

	// Transfert Certificat : Pour la démo
	$('#transfert-modal .close').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-acceptation-done').hide();
		$('.step-rejection-done').hide();
		$('.step-init').fadeIn();
		$('#transfert-modal').removeClass('on');
		$('.side-modal').removeClass('on');
	});
	$('.sticky-buttons .accept').click(function() {
		$('.step-init').hide();
		$('.step-accept').fadeIn();
	});
	$('.sticky-buttons .reject').click(function() {
		$('.step-init').hide();
		$('.step-reject').fadeIn();
	});
	$('.sticky-buttons .goto-init').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-init').fadeIn();
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
	});
	$('.sticky-buttons .confirm-acceptation').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-acceptation-done').fadeIn();
	});
	$('.sticky-buttons .confirm-rejection').click(function() {
		$('.step-accept').hide();
		$('.step-reject').hide();
		$('.step-rejection-done').fadeIn();
	});

	$('.insurance-card .block-content').click(function() {
		$('.side-modal').addClass('on');
		$('#insurance-details-modal .sticky-buttons').fadeIn();
		$('#shadow').fadeIn();
		$('body').css({'overflow':'hidden'});
	});
	$('#insurance-details-modal .goto-init').click(function() {
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
		$('body').css({'overflow':'initial'});
	});
	$('#shadow').click(function() {
		$('.side-modal').removeClass('on');
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
		$('body').css({'overflow':'initial'});
	});

	// Transfert details : Pour la démo
	$('.transfert-card.open-modal').click(function() {
		$('.transfert-detail-modal').addClass('on');
	});
	$('.inner-slide').click(function() {
		$(this).parent().find('.table-wrapper').slideToggle();
		$(this).toggleClass('on');
	});

	// Emmission de nouveaux certificats
	$('.summernote').summernote();

	resizeUploadWrapper();
	$(window).on('resize', function () {
		resizeUploadWrapper();
	});
	function resizeUploadWrapper() {
		$('.new-certificats-forms .upload-img').each(function() {
			var width = $(this).width();
			$(this).height(width);
		});
	}

	// Slick
	$('.my-certificat-wrapper .certificat-slider').slick({
		infinite: true,
		slidesToShow: 3,
		slidesToScroll: 3,
		prevArrow: $('.button-prev'),
		nextArrow: $('.button-next'),
		responsive: [
		{
			breakpoint: 1190,
			settings: {
				slidesToShow: 1,
				slidesToScroll: 1,
				dots: true,
			}
		}
		]
	});

	// Certificat - Tabs
	$('.certificat-tabs li').click(function () {
		var classTarget = $(this).attr('data-target');
		$('.certificat-tabs li').removeClass('active');
		$(this).addClass('active');
		$('.tab-target').hide();
		$('.'+classTarget).show();
	});

	$('.historique-button').click(function() {
		$('.historique').toggleClass('on');
		if($('.historique').hasClass('on')) {
			$('body').css({'overflow':'hidden'});
		} else {
			$('body').css({'overflow':'initial'});
		}
		$('.auth-block-top-bar .certificate-id').addClass('blur');
		$('.auth-block-top-bar .bubbles').addClass('blur');
		$('.center-wrapper .title').addClass('blur');
		$('.tabs').addClass('blur');
		$('.specifications').addClass('blur');
		$('.description').addClass('blur');
		$('.images').addClass('blur');
		$('.documents').addClass('blur');
		$('.btns-wrapper').addClass('blur');
	});
	$('.certificat-mob .close').click(function() {
		$('body').css({'overflow':'initial'});
		$('.historique').removeClass('on');
		$('.auth-block-top-bar .certificate-id').removeClass('blur');
		$('.auth-block-top-bar .bubbles').removeClass('blur');
		$('.center-wrapper .title').removeClass('blur');
		$('.tabs').removeClass('blur');
		$('.specifications').removeClass('blur');
		$('.description').removeClass('blur');
		$('.images').removeClass('blur');
		$('.documents').removeClass('blur');
		$('.btns-wrapper').removeClass('blur');

	});

	// Certificat - Modal
	$(".modal-link").click(function(e) {
		e.preventDefault();
		$('.modal').fadeIn(300);
		$('body').css({'overflow':'hidden'});
	});
	$(".close-modal").click(function(e) {
		e.preventDefault();
		$('.modal').fadeOut(300);
		$('.side-modal').removeClass('on');
		$('body').css({'overflow':'initial'});
		$('.shadow-wrapper').fadeOut();
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
	});
	$('.modal-goto-step0').click(function(e) {
		e.preventDefault();
		$('.modal').fadeOut(300);
		$('.side-modal').removeClass('on');
		$('body').css({'overflow':'initial'});
		$('.shadow-wrapper').fadeOut();
		$('#shadow').fadeOut();
		$('#transfer-certificat .sticky-buttons').fadeOut();
	});

	// Burger
	$('.burger').click(function() {
		$('.side-part').addClass('on');
		$('.side-menu-container').addClass('on');
		$('body').addClass('hidden');
	});
	$('.side-menu-container .close').click(function() {
		$('.side-part').removeClass('on');
		$('.side-menu-container').removeClass('on');
		$('body').removeClass('hidden');
	});

	// Wallet
	$('.cards-wrapper .title-wrapper').click(function() {
		$(this).next('.cards').slideToggle();
		$(this).find('.arrow').toggleClass('closed');
	});

	// Case serial
	if($(window).width() > 665) {
		$('.case-serial-info').mouseover(function() {
			$('.case-serial-hover').addClass('on');
		});
		$('.case-serial-info').mouseout(function() {
			$('.case-serial-hover').removeClass('on');
		});
	} else {
		$('.case-serial-info').click(function() {
			$('body').css({'overflow':'hidden'});
			$('.case-serial-hover').addClass('on');
		});
		$('.case-serial-hover .close').click(function() {
			$('body').css({'overflow':'auto'});
			$('.case-serial-hover').removeClass('on');
		});
	}

	// Case serial

	$('.info-popin-info').click(function() {
		var target = '.'+$(this).attr('data-target');
		$('body').css({'overflow':'hidden'});
		$(target).addClass('on');
		console.log(target);
	});

	$('.info-popin-hover .close').click(function() {
		$('body').css({'overflow':'auto'});
		$('.info-popin-hover').removeClass('on');
	});

	// Certificat Popin
	$('.open-certificat-popin').click(function(e) {
		e.preventDefault();
		$('.certificat-popin').addClass('on');
	});
	$('.close-certificat-popin').click(function() {
		$('.certificat-popin').removeClass('on');
	});

	// Insurance
	$('#insurance-blocks').click(function() {
		$(this).toggleClass('on');
		$(".insurance-content-wrapper").find('.blocks-wrapper').slideToggle();
	});

	// Popin insurance
	$('#popin-go-step-2').click(function(e) {
		e.preventDefault();
		$('.step-1').hide();
		$('.step-2').show();
		//$('.popin-btns-step1').hide();
		//$('.popin-btns-step2').show();
	});
	$('#popin-go-step-1').click(function(e) {
		e.preventDefault();
		$('.step-1').show();
		$('.step-2').hide();
		//$('.popin-btns-step1').show();
		//$('.popin-btns-step2').hide();
	});

	// Async scroll
	$('.historique-button.async').hide();
	$(window).scroll(function () {
		var height = 2400;
		if (!$("#insurance-blocks").hasClass('on')) {
			height = 600;
		}
		if ($(window).scrollTop() > height) {
			$('.historique-button.async').fadeIn();
		} else {
			$('.historique-button.async').fadeOut();
		}
	});

    // Side menu insurance
    $('.open-validation').click(function() {
    	$('.shadow-wrapper').fadeIn();
    });

	// Side menu -  Certificats List 
	$('.dropdown-display').click(function() {
		$(this).toggleClass("open");
	});
	$('.certificats-list-container tr label').click(function() {
		var that = $(this).closest("tr");

		if(that.hasClass('enable')) {

			if( $('.icon-circle:hover').length == 0 
				&& $('.id-font4:hover').length == 0
				&& $('.tooltip-container:hover').length == 0
				&& $('.tooltip:hover').length == 0) {

				if( that.find('input[type=checkbox]').is(':checked') ) {
					that.removeClass('selected');
				} else {
					that.addClass('selected');
				}
			}

			setTimeout(function() {

			if($('#line3').is(':checked') || $('#line4').is(':checked')) {
				if(!$('#line3').is(':checked') || !$('#line4').is(':checked')) {
					$('.single-selected-options').addClass('on');
					$('.selected-options').addClass('on');
					$("#all").prop( "checked", false );
					var count = 1;
					$('#all + label').html("Sélectionné ("+count+")");
					$('.options .question').addClass('on');
				} else {
					$('.single-selected-options').removeClass('on');
					$('.selected-options').addClass('on');
					$("#all").prop( "checked", true );
					$('#all + label').html("Tout décocher ("+count+")");
					var count = 2;
					$('#all + label').html("Tout décocher ("+count+")");
					$('.options .question').removeClass('on');
				}
			} else {
				$('.single-selected-options').removeClass('on');
				$('.selected-options').removeClass('on');
				$('.options .question').removeClass('on');

				$('#all + label').html("Tout sélectionner");
				$("#all").prop( "checked", false );
			}

			});
		}
	});
	$('.certificats-list-container #all').click(function() {
		if( $(this).is(':checked') ) {
			$('.selected-options').addClass('on');
			$("#line3").prop( "checked", true );
			$("#line4").prop( "checked", true );
			$("#line3").closest('tr').addClass('selected');
			$("#line4").closest('tr').addClass('selected');
			$("#all").prop( "checked", true );
			var count = 2;
			$('#all + label').html("Tout décocher ("+count+")");
		} else {
			$('.selected-options').removeClass('on');
			$("#line3").prop( "checked", false );
			$("#line4").prop( "checked", false );
			$("#line3").closest('tr').removeClass('selected');
			$("#line4").closest('tr').removeClass('selected');
			$("#all").prop( "checked", false );
			$('#all + label').html("Tout sélectionner");
		}

		if($('#line3').is(':checked') || $('#line4').is(':checked')) { 
			if(!$('#line3').is(':checked') || !$('#line4').is(':checked')) {
				$('.options .question').addClass('on');
			} else {
				$('.options .question').removeClass('on');
			}
		} else {
			$('.options .question').removeClass('on');
		}
	});
	$('.certificats-list-container .dropdown-options').click(function() {
		/*if( $('#certificats-transferes').is(':checked') ) {
			$(".table-wrapper table tr.disabled").show();
			$('.display-label').html("Afficher : Certificats transférés");
		} else {
			$(".table-wrapper table tr.disabled").hide();
		}
		if( $('#certificats-en-portefeuille').is(':checked') ) {
			$(".table-wrapper table tr.enable").show();
			$('.display-label').html("Afficher : Certificats en portefeuille");
		} else {
			$(".table-wrapper table tr.enable").hide();
		}
		if( $('#certificats-transferes').is(':checked') && $('#certificats-en-portefeuille').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		}
		if( !$('#certificats-transferes').is(':checked') && !$('#certificats-en-portefeuille').is(':checked')) {
			$('.display-label').html("Afficher : Aucun certificat");
		}*/
		if( $('#en-attente').is(':checked') ) {
			$(".table-wrapper table tr.en-attente").show();
		}
		if(! $('#en-attente').is(':checked') ) {
			$(".table-wrapper table tr.en-attente").hide();
		}

		if( $('#acceptes').is(':checked') ) {
			$(".table-wrapper table tr.accepte").show();
		}
		if(! $('#acceptes').is(':checked') ) {
			$(".table-wrapper table tr.accepte").hide();
		}

		if( $('#refuses').is(':checked') ) {
			$(".table-wrapper table tr.refuse").show();
		}
		if(! $('#refuses').is(':checked') ) {
			$(".table-wrapper table tr.refuse").hide();
		}

		if( $('#en-attente').is(':checked') && $('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		} else if (!$('#en-attente').is(':checked') && $('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Acceptés / Réfusés");
		} else if ($('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente / Réfusés");
		} else if ($('#en-attente').is(':checked') && $('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente / Acceptés");
		} else if (!$('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && $('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Réfusés");
		} else if (!$('#en-attente').is(':checked') && $('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Acceptés");
		} else if ($('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : En attente");
		} else if (!$('#en-attente').is(':checked') && !$('#acceptes').is(':checked') && !$('#refuses').is(':checked')) {
			$('.display-label').html("Afficher : Aucun");
		}
	});
	$('body').click(function() {
		if($('.dropdown-options:hover').length < 1 && $('.dropdown-display:hover').length < 1) {
			$('.dropdown-display').removeClass('open');
		}
	});

	// Medias
	$('.medias-list-container tr label').click(function() {
		var that = $(this).closest("tr");
		$('.options .question').removeClass('on');

			if( $('.icon-circle:hover').length == 0 
				&& $('.id-font4:hover').length == 0
				&& $('.tooltip-container:hover').length == 0
				&& $('.tooltip:hover').length == 0) {

				if( that.find('input[type=checkbox]').is(':checked') ) {
					that.removeClass('selected');
				} else {
					that.addClass('selected');
				}
			}

			setTimeout(function() {

			if($('#line1').is(':checked') || $('#line2').is(':checked')) {
				$('.selected-options').addClass('on');

				if(!$('#line1').is(':checked') || !$('#line2').is(':checked')) {
					$('.single-selected-options').addClass('on');
					$("#all").prop( "checked", false );
					var count = 1;
					$('#all + label').html("Sélectionner ("+count+")");
					$('.options .question').addClass('on');
				} else {
					$('.single-selected-options').removeClass('on');
					$("#all").prop( "checked", true );
					var count = 2;
					$('#all + label').html("Tout décocher ("+count+")");
					$('.options .question').removeClass('on');
				}
			} else {
				$('.single-selected-options').removeClass('on');
				$('.selected-options').removeClass('on');
				$('.options .question').removeClass('on');

				$('#all + label').html("Tout sélectionner");
				$("#all").prop( "checked", false );
			}

			});
		
	});
	$('.medias-list-container #all').click(function() {
		if( $(this).is(':checked') ) {
			$('.selected-options').addClass('on');
			$("#line1").prop( "checked", true );
			$("#line2").prop( "checked", true );
			$("#line1").closest('tr').addClass('selected');
			$("#line2").closest('tr').addClass('selected');
			$("#all").prop( "checked", true );
			var count = 2;
			$('#all + label').html("Tout décocher ("+count+")");
		} else {
			$('.selected-options').removeClass('on');
			$("#line1").prop( "checked", false );
			$("#line2").prop( "checked", false );
			$("#line1").closest('tr').removeClass('selected');
			$("#line2").closest('tr').removeClass('selected');
			$("#all").prop( "checked", false );
			$('#all + label').html("Tout sélectionner");
		}
		if($('#line1').is(':checked') || $('#line2').is(':checked')) {
			if(!$('#line1').is(':checked') || !$('#line2').is(':checked')) {
				$('.options .question').addClass('on');
			} else {
				$('.options .question').removeClass('on');
			}
		} else {
			$('.options .question').removeClass('on');
		}


	});
	$('.medias-list-container .dropdown-options').click(function() {
		if( $('#type-images').is(':checked') ) {
			$(".table-wrapper table tr.image").show();
			$('.display-label').html("Afficher : Images"); 
		} else {
			$(".table-wrapper table tr.image").hide();
		}
		if( $('#type-documents').is(':checked') ) {
			$(".table-wrapper table tr.document").show();
			$('.display-label').html("Afficher : Documents"); 
		} else {
			$(".table-wrapper table tr.document").hide();
		}
		if( $('#type-images').is(':checked') && $('#type-documents').is(':checked')) {
			$('.display-label').html("Afficher : Tout");
		}
		if( !$('#type-images').is(':checked') && !$('#type-documents').is(':checked')) {
			$('.display-label').html("Afficher : Aucun type");
		}
	});

	$('.open-add-media-modal').click(function(e) {
		e.preventDefault();
		$('.add-media-overlay').addClass('active');
		$('body').css({'overflow':'hidden'});
	});
	$('.add-media-overlay .close').click(function(e) {
		e.preventDefault();
		$('.add-media-overlay').removeClass('active');
		$('body').css({'overflow':'initial'});
	});
	
	$('.open-transfer-modal').click(function(e) {
		e.preventDefault();
		$('.side-modal').addClass('on');
		$('#transfer-certificat .sticky-buttons').fadeIn();
		$('#shadow').fadeIn();
		$('body').css({'overflow':'hidden'});
	});

	// Models
	$('.open-add-model-modal').click(function(e) {
		e.preventDefault();
		$('.add-model-overlay').addClass('active');
		$('body').css({'overflow':'hidden'});
	});
	$('.add-model-overlay .close').click(function(e) {
		e.preventDefault();
		$('.add-model-overlay').removeClass('active');
		$('body').css({'overflow':'initial'});
	});
	$('.add-model-overlay .close-like').click(function(e) {
		e.preventDefault();
		$('.add-model-overlay').removeClass('active');
		$('body').css({'overflow':'initial'});
		$('.step').hide();
		$('.step1').show();
	});
	$('.add-model-overlay .step1 div').on('click', function() {
		if($(this).hasClass('select-items')) {
			$('.step1 .button-main').removeClass('disabled');
		}
	});
	$('.stepfile .upload-wrapper').click(function() {
		if($('.stepfile .button-main').hasClass('ready')) {
			$('.stepfile .button-main').removeClass('disabled');
		}
		setTimeout(function() {
			$('.stepfile .button-main').addClass('ready');
		});
	});
	$('.stepfile .checkbox-wrapper').click(function() {
		if($('.stepfile .button-main').hasClass('ready')) {
			$('.stepfile .button-main').removeClass('disabled');
		}
		setTimeout(function() {
			$('.stepfile .button-main').addClass('ready');
		});
	});

	$('.modal-goto-step1').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step1').fadeIn();
		}
	});
	$('.modal-goto-step2').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step2').fadeIn();
		}
	});
	$('.modal-goto-step3').click(function(e) {
		e.preventDefault();
		if(!$(this).hasClass('disabled')) {
			$('.step').hide();
			$('.step3').fadeIn();
		}
	});
	

	//

	function checkTransfertCertificat() {
		//if($('#dest-email').val() != "") {
			if($('#client-final').is(':checked')) {
				if($('#dest-email').val() != "") {
					$('#transfer-certificat .confirm').removeClass('disabled');
				}
				$('.more-infos').hide();
			}
			if($('#point-de-vente').is(':checked')) {
				$('.more-infos').show();
				var location = $('#location + .select-selected').html();
				var sold = $('#sold-date').val();
				console.log(location);
				console.log(sold);
				if(location != "Sélectionner un point de vente" && sold != "") {
					if($('#dest-email').val() != "") {
						$('#transfer-certificat .confirm').removeClass('disabled');
					}
				} else {
					$('#transfer-certificat .confirm').addClass('disabled');
				}
			}
		/*} else {
			$('#transfer-certificat .confirm').addClass('disabled');
			//$('#dest-email').addClass('error');
		}*/
	};

	$('#transfer-certificat').click(function() {
		checkTransfertCertificat();
	});
	$('#transfer-certificat input').change(function() {
		checkTransfertCertificat();
	});

	$("#sold-date").datepicker({
		defaultDate: new Date(),
		inline:true,
		showOtherMonths: true,
		altFormat: "dd/mm/yy",
		dateFormat: "dd/mm/yy",
		minDate: 0,
	    onSelect: function(dateText) {
	    	checkTransfertCertificat();  
	    }
	});
	$("#sold-date").datepicker("setDate", "-0d");

	/*function sleep(delay) {
        var start = new Date().getTime();
        while (new Date().getTime() < start + delay);
      }

	for (var i = 0; i < 9000; i++) {
		console.log("-");
		checkTransfertCertificat();
		sleep(500);
	}*/

	/*$('.tooltip-container').click(function() {
		$(this).toggleClass('on');
	});
	$('body').click(function() {
		if( $('.tooltip-container:hover').length == 0 ) {
			$('.tooltip-container').removeClass('on');
		}
	});$*/


});




